import React from "react";
import PropTypes from "prop-types";

var isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);

const Introtext = props => {
  const { intro, theme } = props;
  let object = {};
  if (isHTML(intro)) {
    object = <p className="introtext" dangerouslySetInnerHTML={{ __html: intro }} />;
  } else {
    object = <p className="introtext"> {intro} </p>;
  }
  return (
    <React.Fragment>
      <div className="introtext"> {object} </div>
      <style jsx>{`
        .introtext {
          animation-name: introtextEntry;
          animation-duration: ${theme.time.duration.long};
          color: ${theme.text.color.primary};

          :global(h2),
          :global(h3) {
            margin: 1.5em 0 1em;
          }

          :global(h2) {
            line-height: ${theme.font.lineHeight.m};
            font-size: ${theme.font.size.l};
          }

          :global(h3) {
            font-size: ${theme.font.size.l};
            line-height: ${theme.font.lineHeight.m};
          }

          :global(p) {
            font-size: ${theme.font.size.m};
            line-height: ${theme.font.lineHeight.xxl};
            margin: 0 0 1.5em;
          }
          :global(ul) {
            list-style-type: disc;
            margin: 0 0 1.5em;
            padding: 0 0 0 1.5em;
          }
          :global(li) {
            margin: 0.7em 0;
            line-height: 1.5;
          }
          :global(a) {
            font-weight: ${theme.font.weight.bold};
            color: ${theme.color.brand.primary};
            text-decoration: underline;
          }
          :global(a.gatsby-resp-image-link) {
            border: 0;
            display: block;
            margin: 2.5em 0;
            border-radius: ${theme.size.radius.default};
            overflow: hidden;
            border: 1px solid ${theme.line.color};
          }
          :global(code.language-text) {
            background: ${theme.color.neutral.gray.c};
            text-shadow: none;
            color: inherit;
            padding: 0.1em 0.3em 0.2em;
            border-radius: 0.1em;
          }
        }

        .introtext a {
          font-weight: ${theme.font.weight.bold};
          color: ${theme.color.brand.primary};
          text-decoration: underline !important;
        }
        @keyframes intotextEntry {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Introtext.propTypes = {
  intro: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired
};

export default Introtext;
