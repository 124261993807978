import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { FaCalendar } from "react-icons/fa/";
import { FaTag } from "react-icons/fa/";
import { FaClock } from "react-icons/fa/";
import { FaUser } from "react-icons/fa/";
import { Link } from "gatsby";

const Meta = props => {
  const { prefix, timeToRead, author: authorName, category, theme } = props;

  return (
    <div className="meta">
      <span>
        <FaCalendar size={18} />
        <div title={moment(prefix).format("MMMM D, YYYY")}>{moment(prefix).fromNow()}</div>
      </span>
      <span>
        <FaClock size={18} />
        <div> {timeToRead + " minutes"} </div>
      </span>
      <span>
        <FaUser size={18} /> {authorName}
      </span>
      {category && (
        <span>
          <FaTag size={18} />
          <Link
            to={`/category/${category
              .toLowerCase()
              .split(" ")
              .join("-")}`}
          >
            <div> {category} </div>
          </Link>
        </span>
      )}

      {/* --- STYLES --- */}
      <style jsx>{`
        .meta {
          display: flex;
          flex-flow: row wrap;
          font-size: 0.8em;
          margin: ${theme.space.m} 0;
          background: transparent;
          color: "#ad89e6";

          :global(svg) {
            fill: ${theme.icon.color};
            margin: ${theme.space.inline.xs};
          }

          a:link {
            color: red;
          }
          span {
            align-items: center;
            display: flex;
            text-transform: uppercase;
            margin: ${theme.space.xs} ${theme.space.s} ${theme.space.xs} 0;
          }
        }
        @from-width tablet {
          .meta {
            margin: ${`calc(${theme.space.m} * 1.5) 0 ${theme.space.m}`};
          }
        }
      `}</style>
    </div>
  );
};

Meta.propTypes = {
  prefix: PropTypes.string.isRequired,
  timeToRead: PropTypes.number.isRequired,
  author: PropTypes.string.isRequired,
  category: PropTypes.string,
  theme: PropTypes.object.isRequired
};

export default Meta;
